@use '@gear-js/ui/resets';
@use '@gear-js/ui/typography';

@tailwind base;
@tailwind components;
@tailwind utilities;

.hover:hover {
    cursor: pointer;
}
